<template>
    <div>
        <header-portrate /> 
        <exports-print ref="exportbar" />
    <div>
       <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;margin-top:20px !important;`">
            <v-col cols="12" md="10" sm="12" :style="`direction:`+lang.dir+`;text-align:`+lang.algin">
                <form autocomplete="off">
                    <v-row>
                        
                    </v-row>
                </form>
            </v-col>
            <v-col cols="12" md="2" sm="12" class="pt-9" :style="`direction:`+lang.ldir+`;text-align:`+lang.lalgin">
                <button id="multiply-button" :style="`direction:`+lang.dir" :class="`multiply-button-`+lang.lalgin" @click="_AddPurch()" v-b-toggle.add_addporch>
                    <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_sinventory}}</div>
                </button>
            </v-col>
            <v-col cols="12" md="12" sm="12" :style="`direction:`+lang.dir+`;margin-top:3px;text-align:`+lang.align">
                <v-data-table
                    :headers="headers"
                    :items="purchrows"
                    :search="search"
                    :items-per-page="15"
                    class="elevation-1"
                    hide-default-footer
                >
                <template v-slot:item="row">
                <tr>
                    <td class="text-center" style="width:70px">{{ row.item.namear }}</td>
                    <td class="text-center" nowrap>{{ row.item.nameen }}</td>
                    <td class="text-center" style="width:70px;background:red;border-bottom:2px solid #FFF !important;">
                    <v-btn style="font-size:0.2em;background:red;width:70px;color:#FFF;height:25px !important;margin-right:5px;" @click="delItem(row.item)">{{lang.delete}}</v-btn>
                    </td>
                    <td class="text-center" style="width:70px;background:blue;border-bottom:2px solid #FFF !important;">
                    <v-btn style="font-size:0.2em;background:blue;width:70px;color:#FFF;height:25px !important;" @click="ViewItem(row.item)" v-b-toggle.viewPurch>
                        {{lang.view}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin" style="display:none"></i>
                    </v-btn>
                    </td>
                </tr>
                </template>
                
                </v-data-table>
                
            </v-col>
        </v-row> 
    </div>
        <Footer />
        <div class="loadingPage" v-if="$store.state.showLoading">
        <v-progress-circular
            :width="3"
            color="green"
            indeterminate
        ></v-progress-circular>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import breadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import ExportsPrint from '@/components/exports-print.vue';
export default{
    components:{
        breadCrumbs,TabsComp,
        HeaderPortrate,Footer, ExportsPrint
    },
    data() {
        return {
            cpage:0,
            id:0,
            active_tab:2,
            search: '',
            purchrows:[],
            // sdate: '',
            // edate: '',
            vendor_name: '',
            mobile: '',
            invoiceno: '',
            page: 1,
            pageCount:0,
            sd: {
                day: 1,
                month: (new Date()).getMonth(),
                year: (new Date()).getFullYear(),
            },
            ed: {
                day: (new Date()).getDate() + 1,
                month: (new Date()).getMonth() + 1,
                year: (new Date()).getFullYear(),
            }
        }
    },
    computed: {
       lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        sdate: function() {
            return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
            return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
            const start_year = this.$store.state.licenseType.startYear;
            let t = [{text: this.lang.year, value: -1}];
            for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
                t.push({text: start_year + i, value: start_year+i})
            }
            
            }
            return t
        },
        daysList: function(){
            return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
            ]
        },
        monthsList: function(){
            return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            ]
        },
        headers: function() {
            return [
                {
                    text: this.lang.date,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.inventory_item,
                    align: 'center',
                    sortable: false,
                    value: 'vendor',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.start_qtty,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.cost_price,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.added_by,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.status,
                    align: 'center',
                    sortable: false,
                    value: 'bill_date',
                    class:"backBlack",
                    printRemove: 0
                },
                {
                    text: this.lang.delete,
                    align: 'center',
                    sortable: false,
                    class:"backBlack",
                    value: 'none',
                    printRemove: 1
                },
                {
                    text: this.lang.action,
                    align: 'center',
                    sortable: false,
                    class:"backBlack",
                    value: 'none',
                    printRemove: 1
                }
            ]
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;         
        },
        items: function() {
            return {
                    text: this.lang.purchase,
                    disabled: true,
                    to: '/finance/purchase',
                }
        },
        ptotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].total) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pvat: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].vat) + parseFloat(x);
            }
            return this.$RoundNum(x);
        },
        pftotals: function(){
            let x = 0;
            for(let i = 0;i < this.purchrows.length;i++){
                x = parseFloat(this.purchrows[i].ftotal) + parseFloat(x);
            }
            return this.$RoundNum(x);
        }
    },
    methods: {
        getStartDate(){
            let d = (new Date()).getDate();
            let m = (new Date()).getMonth() + 1;
            let y = (new Date()).getFullYear();
            if(parseInt(d) < 30){
            d = parseInt(30) - parseInt(d);
            if(parseInt(m) == 1){
                m = 12
                y = parseInt(y) - 1
            }
            else if(parseInt(m) > 1){
                m = parseInt(m) - 1;
            }
            }else if(parseInt(d) == 30){
            d = 1;
            }else{
            d = parseInt(d) - parseInt(30);
            }
            this.sd.day = 1;
            this.sd.month = 1;
            this.sd.year = y;
        },
        
        preparedata(){ 
            let exdata = {
                list: "purchaseList",
                title: this.lang.purchase,
                data: [],
            }
            for(let i=0;i<this.purchrows.length;i++){
                exdata.data.push(this.purchrows[i]);
            }
            // // console.log(exdata);
            this.$refs.exportbar.exportdata = exdata;
            this.$refs.exportbar.sdate = this.sdate;
            this.$refs.exportbar.edate = this.sdate;
  
        },
        _AddPurch(){
            
            this.$refs.addPurch.vendorid = '';
            this.$refs.addPurch.bill_number ='';
            this.$refs.addPurch.modal = false;
            this.$refs.addPurch.vendor = '';
            this.$refs.addPurch.mobile = '';
            this.$refs.addPurch.bill_date = '';
            this.$refs.addPurch.vat_number = '';
            this.$refs.addPurch.vendor_address = '';
            this.$refs.addPurch.paytype = 1;
            this.$refs.addPurch.vattype = 2;
            this.$refs.addPurch.full_total =0;
            this.$refs.addPurch.full_vat =0;
            this.$refs.addPurch.full_ftotal =0;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.invtype =1;
            this.$refs.addPurch.cashajl = 1;
            this.$refs.addPurch.tbrows =[];
            this.$refs.addPurch.newrow = {
                item_number: '',
                item_name: '',
                unit: this.lang.pace,
                qty: 1,
                item_price: '',
                total: '',
                vat: '',
                ftotal: ''
            };
            this.$refs.addPurch.enableAdd();
        },
        exportE(type){
            let url = '';
            if(type == 'e'){
                url = this.$store.state.SAMCOTEC.e_path;
            }else{
                url = this.$store.state.SAMCOTEC.pd_path;
            }
            const myhead = this.headers;
            const mydata = this.purchrows;
            const reportname = 'فواتير المشتريات';
            const period = this.sdate + ' - ' + this.edate;
            const head = []
            const keys = []
            
            if(myhead.length > 0){
                for(let i = 0;i < myhead.length;i++){
                    head.push(myhead[i].text);
                    if(myhead[i].text != 'الاجراء')
                        keys.push(myhead[i].value);
                }
            }
            
            const post = new FormData();
            post.append("export" , 'inrecepit');
            post.append("keys",keys);
            post.append("header",head);
            if(mydata.length > 0){
                for(let i = 0;i< mydata.length;i++){
                    for(const [key, value] of Object.entries(mydata[i])){
                       post.append('data['+i+']['+key+']',value);
                    }
                }
            }
            post.append("reportname",reportname);
            post.append("period",period);
            axios.post( url , post)
            .then((res) => {
                window.open('../api/'+res.data.url,'_blank');
            })
        },
        printMe(item){
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('id',item.id);
        },
        ExportIt(type){
            let url = this.$store.state.SAMCOTEC.e_path+'?';
            if(type != 'e'){
                let url = this.$store.state.SAMCOTEC.pd_path+'?inrecepit';
            }
            const post = new FormData();
            post.append('export','inrecepit');
            post.append('type',1);
            post.append('sdate','');
            post.append('edate','');
            axios.post(
                url, post
            ).then((res) => {
                var rest = res.data;
                window.open('../api' + rest.url,'_blank')
            })
        },
        ViewItem(item){
            this.$refs.viewMenow.billid = item.id;
            this.$refs.viewMenow.getBill();
            
            //this.$router.push({path: '/view-purchase/'+item.id})
        },
        getNext(page){
            this.cpage = parseInt(page) - 1;
            this.getInvoice();
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',this.id);
            post.append('data[sdate]',this.sdate);
            post.append('data[edate]',this.edate);
            post.append('data[type]',1);
            post.append('data[vendor_name]',this.vendor_name);
            post.append('data[mobile]',this.mobile);
            post.append('data[invoiceno]',this.invoiceno);
            post.append('data[cpage]',this.cpage);
            this.$store.state.showLoading = true
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                this.$store.state.showLoading = false
                const res = response.data;
                this.pageCount = parseInt(response.data.results.allres)/15
                if((parseFloat(this.pageCount) - parseInt(this.pageCount)) != 0)
                    this.pageCount = parseInt(parseInt(response.data.results.allres)/15) +1;

                this.purchrows = res.results.data;
            });
        },
        delItem(item)
        {
            const post = new FormData();
            post.append('type','delPurchase');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.getInvoice();
            });
        }
    },
    created() {
        this.getStartDate();
        this.getInvoice();
    },
    
}
</script>